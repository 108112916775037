@import "variable";
body {
    font-family: 'Roboto',  sans-serif;
    * {
            font-size: $default-font-size;
        }
    }
    
    .fs-default {
        font-size: $default-font-size;
}

#kt_app_header_container {
    background-color: var(--color-header-app-container);
}

.color-red {
    color: red !important;
}
.text-gray {
    color: #6C757D !important;
}
.bg-table-gray {
    background-color: #ced4da;
}

.header-modal {
    background: white;
    padding: 10px 24px !important;

    .modal-title {
        font-size: $modal-title-fs;
        font-weight: 700;
        color: $color-text-cyan !important;
        text-transform: uppercase;
    }

    .btn-close {
        filter: initial;
        background-size: 12px;
    }
}

.modal-header {
    .modal-title {
        font-size: $modal-title-fs;
        font-weight: 700;
        color: $color-text-cyan !important;
        text-transform: uppercase;
    }

    .btn-close {
        filter: initial;
        background-size: 12px;
    }
}

.modal-body {
    padding: 16px 24px !important;
}

.resize-none {
    resize: none !important;
    height: auto !important;
}

.customs-input-check {
    height: 20px;
    width: 20px;
    border-radius: 3px !important;
}

.searchTextField {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    display: flex;
    align-items: center;
    border-left: 1px solid #A9A9A9;
    width: 32px;
    justify-content: center;
    cursor: pointer;

    i {
        font-size: 16px;
    }

    &:hover {
        i {
            font-size: 18px;
            color: #13a7a4;
        }
    }
}

.background-image-none {
    background-image: none !important;
}

.customs-form-check {
    display: flex;
    align-items: center;
    margin-right: 5px;

    input {
        width: 20px;
        height: 20px;
        border-radius: 3px !important;
        margin-right: 5px;
        cursor: pointer;
    }

    label {
        color: #181C32;
        cursor: pointer;
    }
}

.customs-form-check__table {
    display: flex;
    align-items: center;
    justify-content: center;

    input {
        width: 18px;
        height: 18px;
        border-radius: 3px !important;
        cursor: pointer;
    }
}

.customs-form-check__radio__table {
    display: flex;
    align-items: center;
    justify-content: center;

    input {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        cursor: pointer;
    }

    input:checked {
        background: #13a7a4;
        cursor: pointer;
    }

    label {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

.customs-form-check__radio {
    display: flex;
    align-items: center;

    input {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        cursor: pointer;
    }

    label {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
    }
}

.text-lable-input {
    display: flex;
    font-size: $default-font-size;
    font-weight: 500;
    align-items: center;
    color: #000000
}

.customs-input {
    height: 29px;
    font-size: $default-font-size;
    border-color: $color-border-gray !important;
    border-radius: 3px;
    padding: 1px 4px;

    &:disabled {
        border-color: $color-border-gray !important;
    }
    &.ps-29px{
        padding-right: 29px !important;
    }
    
}
.variant-standard {
    border: none;
    box-shadow: none;
    border-radius: 0;
    padding: 0px;
    border-bottom: 1px solid #ced4da;
}

.h-33px{
    height: 33px !important;
}
.radius-3px {
    border-radius: 3px !important;
}

.customs-tabs {
    background-color: #ffffff !important;
    height: 33px;
    flex-wrap: nowrap;
    overflow-y: hidden;

    .nav-link {
        background-color: #ffffff;
        // border: none;
        color: #000000;
        padding: 3px 8px !important;
        font-size: $default-font-size;
            ;
        width: max-content;
        font-size: $default-font-size;

        &.active {
            background: white;
            color: $color-text-blue-custom !important;
            font-weight: 700;
            position: relative;
            border: none;

            i {
                color: #ececec;

                &:hover {
                    color: #AAAABA;
                }
            }
             .label {
                span {
                    font-size: $default-font-size;
                    position: relative;
                    &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    top: 20px;
                    background: $color-text-blue-custom;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
                }
                
             }   
        }

        &:hover {
            opacity: 0.8;
            color: var(--color-primary);
            border: none;
        }
    }

    .label {
        position: relative;

        i {
            position: absolute;
            top: -6px;
            right: -13px;
            padding: 10px;
        }
    }
}

.custom-placeholder {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.custom-modal-body {
    overflow-y: scroll;
    max-height: 500px;
}

.label-fw {
    color: #3F4254;
    font-weight: 500;
}

.text-fw {
    color: #A1A5B7;
    font-size: 0.925rem;
    margin: 0;
}

.box_shadow-93 {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
}

.line-left {
    border-left: 1px solid rgba(95, 95, 95, 0.2);
}

.customs-table {
    height: calc(100vh - 370px);
    overflow: auto;
    position: relative;

    .table {
        border: 1px solid $color-border-table !important;
        height: calc(100vh - 360px);
        overflow: auto;
        position: relative;

        .group {
            background-color: #fafafa !important;

            .title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 10px;
                font-weight: 700;
            }
        }

        thead {
            position: sticky;
            top: 0;
            left: 0;
            z-index: 1;

            tr:first-child th {
                background: var(--color-linear-teal) !important;
                color: #ffffff;
                font-weight: 700;
            }
        }

        tbody {
            tr {
                height: 35px;
            }
        }

        th,
        td {
            border: 1px solid $color-border-table;
            background-color: white;
            padding: 5px 10px;
            vertical-align: middle;
        }

        th {
            text-align: center;
        }


        .group-row {
            border-bottom: 2px solid #000;
        }

        .group-row.expanded {
            border-bottom: none;
        }

        .arrow-cell {
            width: 20px;
        }

        .arrow {
            display: inline-block;
            width: 8px;
            height: 8px;
            border-top: 1px solid hsl(0, 0%, 25%);
            border-right: 1px solid hsl(0, 0%, 25%);
            transform: rotate(45deg);
            transition: transform 0.2s ease-in-out;
            cursor: pointer;
        }

        .collapsed.arrow {
            transform: rotate(135deg);
        }

    }
}

.box-shadow-36 {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px
}

.z-index {
    &-2 {
        z-index: 2;
    }

    &-11 {
        z-index: 11;
    }

    &-12 {
        z-index: 12;
    }

    &-13 {
        z-index: 13;
    }

    &-1056 {
        z-index: 1056 !important;
    }
    &-1060 {
        z-index: 1500 !important;
    }
}

.customs-width_radio .form-check {
    width: 580px;
}

.customs-collapse-row {

    .table-body {
        overflow-y: scroll;
        max-height: 400px;
    }

    td {
        border: 1px solid rgba(var(--bs-secondary-rgb), var(--bs-border-opacity));
        --bs-border-opacity: 0.8;
    }

    .cell-action {
        //width: 30px;
        //max-width: 30px;
        //min-width: 30px;
        transition: all 0.1s ease-in;
        padding-left: 0.75rem !important;
        //border-right: 0;

    }

    .chevron-rotate-down {
        i:before {
            transform: rotate(90deg);
            transition: all 0.2s ease-in;
        }
    }

    .custom-children {
        td {
            padding: 10px;
        }

        td:nth-child(2) {
            border-left: 0;
        }
    }

    .row-title {
        background: $color-dark-blur-1;

        td {
            padding: 8px;
        }

        td:nth-child(2) {
            border-left: 0;
        }
    }

    .row-parent {
        td {
            --bs-border-opacity: 0.55;
        }
    }
}

.ac-is-invalid {

    ~.invalid-feedback {
        display: block;
    }

    >div {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
    }
}

.fit-content {
    width: fit-content;
    white-space: nowrap;
}

.pointer {
    cursor: pointer;
}

.disabled-tab {
    pointer-events: none;
    cursor: not-allowed;
    color: #ccc;
}

//phiếu in
.dialog_body_scroll {
    max-height: calc(100vh - 200px);
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0 !important;
}

.background-blur {
    background-color: rgb(0 0 0 / 31%);
}

.autocomplete-custom {
    span {
        &+div {
            display: flex;
            // height: 32px;
            align-items: center;
        }
    }
    &>div {
        padding-right: 0;
        border-radius: 6px;
    }

    &.rounded-0 > div {
        border-radius: 0;
    }
    &.radius > div {
        border-radius: 6px;
    }
}

.autocomplete-custom-tiep-nhan {
    span {
        &+div {
            display: flex;
            max-height: 22px;
            align-items: center;
        }
    }
    &>div {
        padding-right: 0;
        border-radius: 3px;
    }

    &.rounded-0 > div {
        border-radius: 0;
    }
    &.radius > div {
        border-radius: 3px;
    }
}
.max-content-width {
    width: max-content;
}

.is-invalid{
    border-color: #f1416c !important
}

.tooltip-inner{
    box-shadow: 2px 2px 5px #888 !important;
}

.text-area{
    textarea{
        resize: none;
       border-radius: 6px; 
    }
    
}

.w{
    &-55{
        width: 55% !important;
    }
    &-45{
        width: 45% !important;
    }
    &-53{
        width: 53% !important;
    }
    &-47{
        width: 47% !important;
    }
}

.min-w-150px{
    min-width: 150px;
}

.text-cyan {
    color: $color-text-cyan !important;
}

.btn-outline {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white !important;
    color: #000 !important;
    font-size: $default-font-size;
    border: 1px solid rgba(0, 0, 0, 0.15) !important;
    font-weight: 400;
    border-radius: 4px;
    padding: 3px 11px !important;
    i {
        color: #000 !important;
        font-size: $default-font-size;
        margin-right: 4px;
        align-items: center;
        padding-right: 0 !important;
        transition: all 0.15s ease-in-out;
    }
    &:hover {
        color: $color-text-cyan !important;
        border: 1px solid #1cced4 !important;
    i {
        color: $color-text-cyan !important;
    }
    }
    &:active, &:focus {
        color: #08979C !important;
        i {
            color: #08979C !important;
        }
    }
    &:disabled {
        background: $bg-button-disabled!important;
        color: $color-disabled-text !important;
        border: 1px solid $color-disabled-text !important;
        pointer-events: none !important;
      }
}

.btn-fill,#dropdown-basic-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-header-table-custom) !important;
    color: #ffffff !important;
    font-size: $default-font-size;
    font-weight: 400;
    border-radius: 4px;
    padding: 4px 8px !important;
    line-height: 21px;
    z-index: 100;
    i{
        color: #ffffff !important;
        font-size: $default-font-size;
        margin-right: 6px;
        align-items: center;
    }
    svg {
        margin-right: 6px;
        align-items: center;
    }
    &:hover {
        background-color: var(--bs-btn-primary-hover) !important;
    }
    &:disabled {
        background: $bg-button-disabled!important;
        color: $color-disabled-text !important;
        border: 1px solid $color-disabled-text !important;
        pointer-events: none !important;
      }
}

.btn-secondary {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bs-btn-secondary) !important;
    color: #ffffff !important;
    font-size: $default-font-size;
    font-weight: 400;
    border-radius: 4px;
    padding: 4px 8px !important;
    line-height: 21px;
    i{
        color: #ffffff !important;
        font-size: $default-font-size;
        margin-right: 6px;
        align-items: center;
    }
    svg {
        margin-right: 6px;
        align-items: center;
    }
}

.h-table {
    height: calc(100% - 40px);
}

.p-table {
    padding: 9px !important;
    line-height: 16px;
}
.fs-14px {
    font-size: 14px;
}
.fs-13px {
    font-size: 13px;
}
.text-status-blue {
    color: rgba(24, 144, 255, 1) !important;
}
.text-status-ocean {
    color: rgba(19, 194, 194, 1) !important;
}
.text-status-green {
    color: rgba(82, 196, 26, 1) !important;
}
.text-status-yellow {
    color: rgba(250, 219, 20, 1) !important;
}
.text-status-orange {
    color: rgba(250, 173, 20, 1) !important;
}
.text-status-purple {
    color: rgba(114, 46, 209, 1) !important;
}
.text-status-red {
    color: rgb(255, 77, 79) !important;
}
.h-calc-vh-110px {
    min-height: calc(100vh - 110px) !important;
}
.fw-400 {
    font-weight: 400 !important;
  }

.dropdown-btn {
    .dropdown-menu.show {
        flex-direction: column;
        box-shadow: 2px 2px 5px #888;
    }

    .btn.btn-primary.dropdown-toggle:after {
        display: none;
    }
}

.dialog-background {
    background-color: rgba(0, 0, 0, 0.2) !important;
}
.dropdown-item:hover {
    background-color: #E6FFFB !important;
}

.margin-x-auto {
    margin: 0 auto;
}

.modal-xl-bigger {
    .modal-xl {
        --bs-modal-width: 1270px;
        height: calc(100vh - 50px) !important;
    }
}

.modal-content {
    max-height: calc(100vh - 50px);
    overflow-y: auto;
}
.modal-header {
    position: sticky;
    z-index: 1001;
    top: 0;
    background: white;
}
.modal-footer {
    position: sticky;
    z-index: 1001;
    bottom: 0;
    background: white;
}

.min-w{
   &-91-px {
      min-width: 91px !important;
    }
   &-120-px {
      min-width: 120px !important;
    }
    &-100-px {
      min-width: 100px !important;
    }
}

.header-modal-cdha {
    padding: 10px 15px !important;
}

.menu-button-cdha {
	padding: 10px 15px !important;
    flex: 0 1 auto;
	
    button {
        font-size: 1rem !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .dropdown-toggle {
		padding: 2px 4.5px !important;
    }

    .dropdown-divider {
        width: 100%;
        border-top-color: #888;
    }

    .dropdown-menu.show {
        inset: auto 20px !important;
        width: max-content;
        padding: 5px 0;
    }

    i {
        font-size: 24px;
        margin-top: 2px;
    }
}

.info-patient-cdha {
    background-color: #FBF1F1;
	padding: 5px 20px !important;
}

.ck-editor__editable {
    min-height: 500px;
}

.ckeditor-custom .ck.ck-editor {
    width: 100%;
}

.ckeditor-custom-mo-ta .ck.ck-editor{
    width: 100%;
    
    .ck-editor__editable {
        min-height: 726px;
    }
}
.confirm-dialog-cdha {
    .modal-footer {
        justify-content: end !important;
    }
}
.text-blue {
	textarea {
		color: #0000FF;
	}
}
.overflow-y-auto {
    overflow-y: auto;
}
.overflow-x-hidden {
    overflow-x: hidden;
}
.rotate-90 {
    transform: rotate(90deg);
}

#print-contents1, #print-contents {
   th, td {
    border-width: 1px !important;
   } 
   * {
    font-family: 'Times New Roman', Times, serif;
   }
}

#print-contents {
    font-family: 'Times New Roman', Times, serif;
}
.sticky-column {
    position: sticky;
    z-index: 999;
    background-color: white;

}

.sticky-header-column {
    background-color: #ced4da;
    position: sticky;
    z-index: 1000;
    top: 0;
}
.border-x {
    border-left: 1px solid $bs-border-color;
    border-right: 1px solid $bs-border-color;
}
.border-full {
    border: 1px solid $color-border-gray !important;
}

.balls {
    width: 3.5em;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
  }
  
  .balls div {
    width: 0.8em;
    height: 0.8em;
    border-radius: 50%;
    background-color: #fc2f70;
    transform: translateY(-100%);
    animation: wave 0.8s ease-in-out alternate infinite;
  }
  
  .balls div:nth-of-type(1) {
    animation-delay: -0.4s;
  }
  
  .balls div:nth-of-type(2) {
    animation-delay: -0.2s;
  }
  
  @keyframes wave {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(100%);
    }
  }
  

.menu-item {
    &.show {
        .menu-link {
            .menu-icon {
                .svg-icon {
                    color: var(--color-header-menu) !important;
                }
                color: var(--color-header-menu) !important;
            }
        }
    }
    .menu-link {
        background-color: var(--color-header-app-container) !important;

        .menu-title {
            font-size: $default-font-size !important;
        }

        &:hover {
            color: var(--color-header-menu) !important;

            .svg-icon {
                color: var(--color-header-menu) !important;
            }

            .menu-title {
                color: var(--color-header-menu) !important;
            }

            &:not(.active) {
                .menu-title {
                    color: var(--color-header-menu) !important;
                }
            }
        }

        &.active {
            font-weight: 500;
            position: relative;

            .menu-title {
                position: relative;
                color: var(--color-header-menu) !important;

                &:hover {
                    color: var(--color-header-menu) !important;
                }
            }
        }

    }
}

.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.form-check-input {
    width: 15px;
    height: 15px;

    &[type=checkbox] {
        border-radius: 0px;
    }
}

.btn .badge-pill-style {
        top: -5px;
        bottom: 10px;
        left: 15px;
}

.app-header-menu {
    .menu-item {
        align-self: stretch;
        display: flex;
        align-items: center;
    
        .menu-link {
            padding: 13px 20px;
        }
    }
}

.pre-line {
    white-space: pre-line;
}
.pre-wrap {
    white-space: pre-wrap;
}
.textarea-custom {
    font-weight: 600;
    width: 100%;
    margin-top: 0;
    border: none;
    outline: none;
    overflow: hidden;
    font-size: $default-font-size;
    // line-height: 22px;
    resize: none
}
.border {
    border-color: #DEE2E6;
}

.h-chi-tiet-dich-vu-tao-phieu {
    height: calc(100vh - 434px);
}

.h-chi-tiet-dich-vu {
    height: calc(100vh - 358px);
}
.modal {
    background-color: rgba(0, 0, 0, 0.2) !important;
}

.form-check {
    display: flex;
    align-items: center;
    gap: 6px;
}

.bg-blue {
    background-color: $bg-blue;
}
.w-tooltip-inner {
    .tooltip-inner {
        min-width: 215px;
    }
}

fieldset.scheduler-border {
  border: 1px solid #ddd;
  padding: 0 1em 1em 1em;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
}

legend.scheduler-border {
    width: inherit;
    padding: 0 5px;
    border-bottom: none;
    font-size: 15px;
    font-weight: 500;
    float: none;
}

a {
    color: $color-text-cyan;
    font-weight: 500;
 }

 [class$="-MenuPortal"] {
    z-index: 1500 !important;
}
.react-select__menu-portal {
    z-index: 1500 !important;
}

.form-check-label-red {
    .form-check-label {
        color: red;
        font-weight: bold;
    }
}

.header-menu-container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    overflow-x: scroll;
    user-select: none;
    width:calc(100vw - 450px);
    span {
       white-space: nowrap;
    }
    scrollbar-width: none;
    
}


.dotted-bottom{
    position: relative;
    width: 100%;
    color: #0000FF;
    &::before {
        content: "";
        border-bottom: 1px dotted black;
        width: 100%;
        position: absolute;
        bottom: 4px;
    }
}

.h-table-dich-vu {
    height: calc(100vh - 225px);
}

.h-table-benh-nhan {
    height: calc(100vh - 148px);
}

.height-max-content {
    height: max-content !important;
}

.h-body-modal-thanh-toan {
    height: calc(100vh - 178px);
}

.h-table-thanh-toan {
    height: calc(100vh - 256px);
}

.h-menu-nhap-thong-tin-pttt {
    height: calc(100vh - 241px);
    overflow-y: auto;
}

.h-menu-quan-ly-dich-vu {
    height: calc(100vh - 349px);
    overflow-y: auto;
}

.h-menu-chi-dinh-dich-vu {
    height: calc(100vh - 318px);
    overflow-y: auto;
}

.h-table-dich-vu-chuyen-khoa {
    height: calc(100vh - 450px);
}

.height-max-content {
    height: max-content !important;
}

.h-modal-mo-ta-chi-tiet {
    height: calc(100vh - 118px);
}

.h-tree-ds-vat-tu-trong-kho {
    height: calc(100vh - 189px);
}

.h-table-ds-vat-tu-trong-kho {
    height: calc(100vh - 188px);
}

.h-tree-lich-su-nhap-xuat{
    height: calc(100vh - 151px);
}

.h-table-lich-su-nhap-xuat{
    height: calc(100vh - 175px);
}

.h-tree-ds-vat-tu-trong-tat-ca-cac-kho {
    height: calc(100vh - 163px);
}

@media screen and (max-width: 1280px) {
    .w-input-116 {
        width: 116px;
    }
    .w-input-127 {
        width: 127px;
    }
    .w-input-128 {
        width: 128.5px;
    }
    .w-input-140 {
        width: 140px;
    }
}

.h-table-nhap-vat-tu {
    height: calc(100vh - 450px);
}

.h-table-ds-vat-tu {
    height: calc(100vh - 289px);
}

.h-table-ds-phieu-ttp {
    height: calc(100vh - 479px);
}

.h-table-ds-vat-tu {
    height: calc(100vh - 153px);
}

.h-tree-ds-vat-tu {
    height: calc(100vh - 128px);
}

.status-column {
    &.orange {
        background-color: orange;
    }

    &.purple {
        background-color: purple;
    }

    &.green {
        background-color: green;
    }

    &.red {
        background-color: red;
    }
}

.form-check-w-25{
    .form-check-input{
        width: 25px;
        height: 25px;
    }
}

.is-active {
    background-color: $color-turquoise;
    border-radius: 5px;

    .text {
        color: white !important;
    }
}

.is-active-cancel {
    background-color: $color-error;
    border-radius: 5px;

    .text {
        color: white !important;
    }
}

.ds-phieu {
    overflow: auto;
    // scrollbar-width: none;
    -ms-overflow-style: none;

    .phieu {
        border-color: $color-turquoise !important;
        .text{
            color: #1A5E83;
        }
    }

    i{
        color: $color-turquoise;
    }
}

.bg-gray {
    background-color: #F0F2F5 !important;
}

.color-subtitle {
    color: var(--color-primary);
    font-size: 16px;
    font-weight: 700;
}

.min-vh-52px {
    min-height: 52px;
}

.border-bt-dashed {
    border-bottom: 1px dashed #DFDFDF;
}

.text-color-note {
    color: #17A2B8;
}

.z-index-1055 {
    z-index: 1055 !important;
}

.react-datepicker-popper {
    z-index: 2 !important;
    line-height: 0 !important;
}

.form-check-input {
    cursor: pointer;
}

.border-check-color {
    .form-check-input {
        border: 1px solid #000000 !important;
    }
}

.btn-custom {
    background-color: $bg-btn-custom !important;
}

.color-blue-custom {
    color: $color-text-blue-custom !important;
}

.btn-blue-outlined-custom {
    background: $color-white !important;
    color: $color-text-blue-custom !important;
    border: 1px solid $color-text-blue-custom !important;
    padding: 4px 8px !important;
    font-size: 1.1rem;


    &:hover,
    &:active,
    &:focus {
        --kt-primary-active: var(--color-linear-green);
        color: $color-hover !important;
        border: none;
        background: $color-bg-hover !important;
    }

    &.white {
        background: white !important;
        color: $color-teal-navy-blue !important;
        border: 1px solid $color-teal-navy-blue !important;

        &:hover {
            background: var(--color-linear-green) !important;
            color: $color-white !important;
            padding: 4px 8px !important;
        }
    }

    &:disabled {
        background: $color-disabled-bg !important;
        color: $color-disabled-text !important;
        border: 1px solid $color-disabled-text !important;
        pointer-events: none !important;
    }
}

.field-tooltip-error {
    color: var(--kt-text-danger);
    padding: 3px 6px;
    border-radius: 3px;
    font-size: 1rem;
    font-weight: 500;
    background-color: #fff;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, .1);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.table-background-th {
    .table-responsive {
        #kt_table_oct {
            th {
                background-color: $color-text-blue-custom !important;
                color: #ffffff;
            }

            td {
                font-weight: 500 !important;
                color: #333 ;
            }
        }
    }
}

.align-items-start {
    align-items: flex-start !important;
}

$inner-form-generate-border-style: 1px dotted rgba(0, 0, 0, 0.4);
$inner-form-generate-cell-border: 1px solid rgba(206, 212, 218, 1);

.inner-form-generate {

    input {
        color: var(--bs-blue);
    }

	.oct-field-wrapper {
		align-items: flex-end !important;
	}

	.text-lable-input {
		margin-bottom: -5px !important;
	}

	input.customs-input {
		border: none;
		border-radius: 0;
		padding: 0 4px;
		border-bottom: $inner-form-generate-border-style;
		padding-top: 6px !important;
        color: var(--color-header-table-custom);

		&::before {
			border-bottom: $inner-form-generate-border-style;
		}
	}

    textarea.customs-input {
        border: $inner-form-generate-border-style;
        padding: 4px !important;
        min-height: 50px;
    }

	.react-datepicker-wrapper {
		.react-datepicker__input-container .react-datepicker__calendar-icon {
			padding-bottom: 0;
		}
	}

	.autocomplete-custom {
		.react-select__control {
			border: none;
			border-bottom: $inner-form-generate-border-style;
		}
	}

	.form-check-input {
		width: 28px;
		height: 28px;
		border-radius: 4px;
		background-color: transparent;
        border: $inner-form-generate-cell-border;
        order: 1;
        margin-left: 0;

		&:checked {
			background-color: transparent;
			background-image: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjM0M3OEQ4IiB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9Ii0zLjUgMCAxOSAxOSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBjbGFzcz0iY2YtaWNvbi1zdmciPgogIDxwYXRoIGQ9Ik0xMS4zODMgMTMuNjQ0QTEuMDMgMS4wMyAwIDAgMSA5LjkyOCAxNS4xTDYgMTEuMTcyIDIuMDcyIDE1LjFhMS4wMyAxLjAzIDAgMSAxLTEuNDU1LTEuNDU2bDMuOTI4LTMuOTI4TC42MTcgNS43OWExLjAzIDEuMDMgMCAxIDEgMS40NTUtMS40NTZMNiA4LjI2MWwzLjkyOC0zLjkyOGExLjAzIDEuMDMgMCAwIDEgMS40NTUgMS40NTZMNy40NTUgOS43MTZ6Ii8+Cjwvc3ZnPg==');
		}
	}

    .form-check-inline {
        margin-bottom: 0 !important;
        padding-left: 0;

        input[type="checkbox"] {
            float: right;
            margin-left: 4px;
        }
    }

    .form-check-label {
        font-style: italic;
        padding-top: 4px;
    }

    .form-check-input:checked + label {
        color: initial;
    }

    .form-check:not(.form-switch) .form-check-input[type=checkbox] {
        background-size: 80%;
    }

    .oct-input-otp input.form-control {
        border: $inner-form-generate-cell-border;
    }

    .label-dot {
        padding-left: 10px;
    }
}

input[type="password"] {
    font:small-caption;
    font-size:16px;
}

@keyframes slideInFromRight {
    from {
        transform: translateX(100%);
        /* Bắt đầu từ ngoài màn hình */
        opacity: 0;
        /* Mờ dần */
    }

    to {
        transform: translateX(0);
        /* Vào đúng vị trí */
        opacity: 1;
    }
}

.slide-in {
    animation: slideInFromRight 0.25s ease-out forwards;
}

.price-info {
    display: grid;
    grid-template-columns: 12fr 2fr;

    &-box {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 0px 30px;

        .d-flex {
            display: flex;
            justify-content: space-between;
        }
    }
}

.modal-90w {
    max-width: none;
    width: 90%;
}

.cursor-wait {
    cursor: wait !important;
}

.modal-80w {
    max-width: none;
    width: 80%;
}

.font-sans {
    font-family: var(--bs-font-sans-serif);
}

.font-mono {
    font-family: var(--bs-font-monospace);
}

.popover-arrow-hidden {
    .popover-arrow {
        display: none;
    }
}