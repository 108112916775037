.xml-table-view-header th:last-child,
.xml-table-view-body td:last-child {
	position: sticky;
	right: 0;
	background-color: var(--bs-white);

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		background-color: var(--bs-border-color);
		width: 1px;
	}
}

.xml-table-view-header th:last-child {
    z-index: 1;
}